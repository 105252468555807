import React, { useState, useCallback } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import { useAuth } from './AuthContext';
import '../css/NewUserSetupCard.css';
import { AiFillYoutube, AiFillInstagram, AiFillTwitterCircle, AiFillEdit } from 'react-icons/ai';
import { BsTiktok } from 'react-icons/bs';
import defaultAvatar from '../assets/img/avatars/avatar_alien_white.png';

function NewUserSetupCard({ onComplete }) {
  const { user, updateUser } = useAuth();
  const [handle, setHandle] = useState(user.handle);
  const [avatar, setAvatar] = useState(null);
  const [email, setEmail] = useState(user.email);
  const [youtube, setYoutube] = useState(user.youtube);
  const [instagram, setInstagram] = useState(user.instagram);
  const [tiktok, setTiktok] = useState(user.tiktok);
  const [twitter, setTwitter] = useState(user.twitter);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(user.disclaimerAccepted);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleSave = async (e) => {
    e.preventDefault();
    console.log('Handle Save Triggered');
    const takenHandles = ['user1', 'admin', 'test'];
    if (takenHandles.includes(handle)) {
      setModalMessage('Handle is already taken. Please choose another one.');
      setShowModal(true);
      console.log('Handle is taken');
    } else if (!disclaimerAccepted) {
      setModalMessage('You must read and agree with the disclaimers to join.');
      setShowModal(true);
      console.log('Disclaimer not accepted');
    } else {
      await updateUser({
        handle,
        avatar,
        email,
        youtube,
        instagram,
        tiktok,
        twitter,
        disclaimerAccepted,
        isFirstSignIn: false,
      });
      console.log('Update user success, notifying parent');
      onComplete(); // Notify parent component
    }
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCropImage(URL.createObjectURL(file));
      setShowCropper(true);
    }
  };

  const handleRemoveAvatar = () => {
    setAvatar(null);
    setShowEditOptions(false);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = async () => {
    const image = new Image();
    image.src = cropImage;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x * scaleX,
      croppedAreaPixels.y * scaleY,
      croppedAreaPixels.width * scaleX,
      croppedAreaPixels.height * scaleY,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        setAvatar(url);
        resolve();
      }, 'image/jpeg');
    });
  };

  const handleCropSave = async () => {
    await getCroppedImg();
    setShowCropper(false);
  };

  const handleEditAvatar = () => {
    if (avatar) {
      setShowEditOptions(true);
    } else {
      document.getElementById('avatar-upload').click();
    }
  };

  const renderSocialMediaForm = () => {
    switch (activeTab) {
      case 'youtube':
        return (
          <Form.Group controlId="formYoutube" className="newuser-profile-form-group">
            <Form.Control
              type="text"
              placeholder="YouTube handle (Optional)"
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
              className="newuser-profile-form-control newuser-profile-form-control-social"
            />
          </Form.Group>
        );
      case 'instagram':
        return (
          <Form.Group controlId="formInstagram" className="newuser-profile-form-group">
            <Form.Control
              type="text"
              placeholder="Instagram handle (Optional)"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              className="newuser-profile-form-control newuser-profile-form-control-social"
            />
          </Form.Group>
        );
      case 'tiktok':
        return (
          <Form.Group controlId="formTiktok" className="newuser-profile-form-group">
            <Form.Control
              type="text"
              placeholder="TikTok handle (Optional)"
              value={tiktok}
              onChange={(e) => setTiktok(e.target.value)}
              className="newuser-profile-form-control newuser-profile-form-control-social"
            />
          </Form.Group>
        );
      case 'twitter':
        return (
          <Form.Group controlId="formTwitter" className="newuser-profile-form-group">
            <Form.Control
              type="text"
              placeholder="Twitter handle (Optional)"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              className="newuser-profile-form-control newuser-profile-form-control-social"
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  const [activeTab, setActiveTab] = useState('youtube');

  return (
    <Container className="newuser-profile-card-container compact">
      <div className="newuser-profile-card-header">
        <span>Set Up Your Profile</span>
      </div>
      <div className="newuser-profile-card-content">
        <Form onSubmit={handleSave}>
          <div className="newuser-avatar-container">
            <label className="newuser-avatar-label" onClick={handleEditAvatar}>
              <img
                src={avatar || defaultAvatar}
                alt="Avatar"
                className="newuser-avatar-image"
              />
              <div className="newuser-avatar-overlay">
                <AiFillEdit className="newuser-avatar-edit-icon" />
              </div>
            </label>
            <input
              id="avatar-upload"
              type="file"
              accept="image/*"
              onChange={handleAvatarChange}
              style={{ display: 'none' }}
            />
            <div className="newuser-handle-email-container">
              <Form.Group controlId="formHandle" className="newuser-profile-form-group">
                <Form.Control
                  type="text"
                  placeholder="myHandle"
                  value={handle}
                  onChange={(e) => setHandle(e.target.value)}
                  className="newuser-profile-form-control"
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="newuser-profile-form-group">
                <Form.Control
                  type="email"
                  placeholder="my@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="newuser-profile-form-control"
                />
              </Form.Group>
            </div>
          </div>
          <div className="newuser-tab-container">
            <Button className={`newuser-tab-button ${activeTab === 'youtube' ? 'active' : ''}`} onClick={() => setActiveTab('youtube')}>
              <AiFillYoutube className="newuser-tab-icon" />
            </Button>
            <Button className={`newuser-tab-button ${activeTab === 'instagram' ? 'active' : ''}`} onClick={() => setActiveTab('instagram')}>
              <AiFillInstagram className="newuser-tab-icon" />
            </Button>
            <Button className={`newuser-tab-button ${activeTab === 'tiktok' ? 'active' : ''}`} onClick={() => setActiveTab('tiktok')}>
              <BsTiktok className="newuser-tab-icon" />
            </Button>
            <Button className={`newuser-tab-button ${activeTab === 'twitter' ? 'active' : ''}`} onClick={() => setActiveTab('twitter')}>
              <AiFillTwitterCircle className="newuser-tab-icon" />
            </Button>
          </div>
          <div className="newuser-tab-content">{renderSocialMediaForm()}</div>
          <Form.Group controlId="formDisclaimer" className="newuser-profile-form-group">
            <Form.Check
              type="checkbox"
              label={
                <span className="newuser-small-text">
                  I have read and agree with the <a href="/disclaimer" target="_blank" rel="noopener noreferrer" className="newuser-disclaimer">Privacy Policy, Fitness and Exercise Disclaimer, and User Content Disclaimer</a>.
                </span>
              }
              checked={disclaimerAccepted}
              onChange={(e) => setDisclaimerAccepted(e.target.checked)}
            />
          </Form.Group>
          <div className="newuser-button-group newuser-center-button">
            <Button className="newuser-btn-home-link" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
      <Modal show={showEditOptions} onHide={() => setShowEditOptions(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="danger" onClick={handleRemoveAvatar} style={{ marginRight: '10px' }}>
            Remove Image
          </Button>
          <Button variant="primary" onClick={() => document.getElementById('avatar-upload').click()}>
            Choose New Image
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={showCropper} onHide={() => setShowCropper(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop your image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="newuser-crop-modal-body">
          {cropImage && (
            <div className="newuser-crop-container">
              <Cropper
                image={cropImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          )}
          <div className="newuser-controls">
            <input
              type="range"
              className="newuser-slider"
              min="1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCropper(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCropSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="newuser-btn-home-link" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default NewUserSetupCard;
