import { React } from "react";
import { Container } from "react-bootstrap";
import "../css/ComingSoon.css";
import img_team from "../assets/img/team.png";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { BsTiktok } from "react-icons/bs";
import { Helmet } from "react-helmet";
import logo_text from "../assets/img/t4a_logo_text_white.svg";

function About() {
  return (
    <div className="page">
      <Helmet>
        <title>thenics4all</title>
        <meta
          name="description"
          content="Step-by-step Handstand pushup progressions, training and tutorials."
        />
        <meta
          name="keywords"
          content="handstand, handstand pushup, hspu, calisthenics, progression, tutorial"
        />
      </Helmet>
      <Container fluid>
        <div className="home-row row-long">
          <h1>
            <a className="home-link" href="/about">
              About
            </a>
          </h1>
          <h2 className="home-row-subtitle">Share, inspire & be inspired</h2>
          <div>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <AiFillInstagram className="social-media-icon-home" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <AiFillFacebook className="social-media-icon-home" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <AiFillYoutube className="social-media-icon-home" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <BsTiktok className="social-media-icon-home" />
            </a>
          </div>
          <div className="home-about">
            <img
              className="home-logo-text-inline"
              src={logo_text}
              alt="thenics4all"
            />
            is a community project that began as a challenge among friends. We quickly realized this journey could take years, so we started documenting our progress and research. We ourselves are at different stages of the journey, and we invite you to join us.
            <br />
            <br />
            All our materials are always available, so feel free to explore them at your own pace. You can also create an account to track and share your own progress.
            We welcome all comments and feedback to help improve the program, so don’t hesitate to reach out.
            <br />
            <br />
            Email: <a href="mailto:thenics4all@gmail.com">thenics4all@gmail.com</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;  
            Reddit: <a href="https://www.reddit.com/r/thenics4all/" target="_blank" rel="noopener noreferrer">r/thenics4all</a>
            <div className="home-about-image">
              <div className="home-about-image-text">
                Our Team
              </div>
              <div className="pashaman">@PashaMan</div>
              <div className="akbar">@Akbar</div>
              <div className="zarrar">@Zarrar</div>
              <img src={img_team} alt="Message" />
            </div>
          </div>
          <div><br></br><br></br></div>
        </div>
      </Container>
    </div>
  );
}
export default About;
