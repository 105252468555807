import React, { useRef, useState, useEffect } from "react";
import styles from "../css/CardHSPUProgressProfile.module.css";
import { BsFillPlayBtnFill, BsPlusCircleFill } from "react-icons/bs"; // Import BsPlusCircleFill
import data_program from "../data/data_program";
import { FaLock } from "react-icons/fa";
import logo from "../assets/img/t4a_logo_white.svg";

function CardHSPUProgressProfile({ data_user }) {
  const cardRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (showVideo && cardRef.current && !cardRef.current.contains(event.target)) {
        closeVideo();
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showVideo]);

  const openVideo = (url) => {
    if (url) {
      setVideoUrl(url);
    } else {
      setVideoUrl(""); // Ensure "Coming soon" is shown when there's no video
    }
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
    setVideoUrl("");
  };

  return (
    <div className={styles.card} ref={cardRef}>
      <table className={styles.cardTable}>
        <thead>
          <tr>
            <th className={styles.firstCol}>Progression</th>
            <th className={styles.secondCol}>Personal Best</th>
          </tr>
        </thead>
        <tbody>
          {data_program.map((data, dataIndex) => (
            <tr key={dataIndex} className={`${styles.level}-${dataIndex + 1}`}>
              <td
                className={`${styles.firstCol} ${styles.progressionCell}`}
                style={{
                  background: data.color,
                  color: data.color === "white" ? "black" : "white",
                  textAlign: "left",
                }}
                onClick={() => openVideo(data.videoUrl)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{data.progression}</span>
                  <button
                    className={styles.watchVideoButton}
                    style={{
                      color: data.color === "white" ? "black" : "white",
                      marginLeft: "auto", // Pushes the icon to the right
                    }}
                  >
                    <BsFillPlayBtnFill className={styles.watchVideoIcon} />
                  </button>
                </div>
              </td>

              {data_user.slice(0, 1).map((user, userIndex) => {
                const userProgress = user.progressData.find(
                  (item) => item.progress_code === data.progress_code
                );
                // Calculate progress percentage, capped at 100%
                const progressPercentage = userProgress
                  ? Math.min((userProgress.best / data.goal) * 100, 100)
                  : 0;


                return (
                  <React.Fragment key={userIndex}>
                    <td
                      className={`${styles.secondCol}`}
                      style={{
                        background: data.color,
                        color: data.color === "white" ? "black" : "white",
                        width: "150px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {userProgress && userProgress.best > 0 ? (
                          <div className={styles.progressBar}>
                            <span className={styles.progressPercentageLeft}>
                              {userProgress.best}
                            </span>
                            <div
                              className={styles.progressFill}
                              style={{
                                width: `${progressPercentage}%`,
                                backgroundColor:
                                  progressPercentage < 25
                                    ? "#E74C3C"  // Bright Red
                                    : progressPercentage >= 25 && progressPercentage < 50
                                      ? "#E67E22"  // Bright Orange
                                      : progressPercentage >= 50 && progressPercentage < 75
                                        ? "#F1C40F"  // Vibrant Yellow
                                        : progressPercentage >= 75 && progressPercentage < 100
                                          ? "#3498DB"  // Bright Blue
                                          : "#2ECC71", // Bright Green for 100%
                              }}
                            ></div>
                            <span className={styles.progressPercentageRight}>
                              {`${progressPercentage.toFixed(0)}%`}
                            </span>
                          </div>
                        ) : (
                          <div className={styles.lockedIcon}>
                            <FaLock />
                          </div>
                        )}
                        {userProgress && userProgress.best > 0 && (
                          <>
                            {/* Play button (check if best_url is null) */}
                            <button
                              className={styles.watchVideoButton}
                              style={{
                                color: data.color === "white" ? "black" : "white",
                              }}
                              onClick={() =>
                                userProgress.best_url
                                  ? openVideo(userProgress.best_url) // Open best_url if not null
                                  : openVideo(null) // Show "Coming Soon" if null
                              }
                            >
                              <BsFillPlayBtnFill className={styles.watchVideoIcon} />
                            </button>

                            {/* Plus icon (always shows "Coming Soon" popup) */}
                            <button
                              className={styles.plusIconButton}
                              style={{
                                color: data.color === "white" ? "black" : "white",
                              }}
                              onClick={() => openVideo(null)} // Opens "Coming Soon" popup
                            >
                              <BsPlusCircleFill className={styles.plusIcon} />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {showVideo && (
        <div className={styles.videoPopup}>
          <div className={styles.videoCloseButton} onClick={closeVideo}>
            &times;
          </div>
          {videoUrl ? (
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <>
              <div className={styles.comingSoonMessage}>Coming soon!</div>
              <img src={logo} alt="thenics4all Logo" className={styles.logo} />
              <div className={styles.comingSoonMessageSubtitle}>
                Working on recording the video!
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default CardHSPUProgressProfile;
