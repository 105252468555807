import React, { useState, useEffect } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import "../css/Login.css";
import "../css/ComingSoon.css";
import { AiFillInfoCircle } from "react-icons/ai";
import { useAuth } from './AuthContext';
import NewUserSetupCard from './NewUserSetupCard';
import ProfilePage from './ProfilePage';
import badgeImage from '../assets/img/badges/1_badge_rabbit.png';

function MockSignInPage() {
  const { isAuthenticated, user, signIn } = useAuth();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationMessage] = useState("");
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  useEffect(() => {
    console.log('User:', user);
    console.log('isFirstSignIn:', user?.isFirstSignIn);
  }, [user]);

  const mockSignIn = () => {
    signIn();
  };

  const handleSetupComplete = () => {
    console.log('Setup Complete');
    setIsSetupComplete(true);
    setShowCongratsModal(true);
  };

  return (
    <div className="page">
      <Container fluid className="auth-container">
        {!isAuthenticated ? (
          <div className="center-button">
            <Button className="home-link" onClick={mockSignIn} style={{ display: 'block', margin: '0 auto' }}>Mock Sign In</Button>
            <div className="noteStyleContainer">
              <div className="noteStyle">
                <AiFillInfoCircle className="infoIcon" />
                <span>Sign in to delete the existing account.</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {user.isFirstSignIn && !isSetupComplete ? (
              <NewUserSetupCard onComplete={handleSetupComplete} />
            ) : (
              <ProfilePage />
            )}
          </div>
        )}
      </Container>

      <Modal show={showNotificationModal} onHide={() => setShowNotificationModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{notificationMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="btn-home-link" onClick={() => setShowNotificationModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCongratsModal} onHide={() => setShowCongratsModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Congratulations!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>You just unlocked Level 1 and earned your first badge</p>
            <img src={badgeImage} alt="Badge" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-home-link" onClick={() => setShowCongratsModal(false)}>
            Let's go!
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MockSignInPage;
