import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './components/Store';
import Navigation from './components/Navigation';
import Home from './components/Home';
import Program from './components/Program';
import Train from './components/Train';
import Progress from './components/Progress';
import About from './components/About';
import Footer from './components/Footer';
import Disclaimer from './components/Disclaimer';
import Test from './components/Test';
import Login from './components/Login'; // Ensure Login is imported
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import MockSignInPage from './components/MockSignInPage'; // Import the mock sign-in page
import { AuthProvider } from './components/AuthContext'; // Import the AuthContext

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));

// Set the initial page title
document.title = 'thenics4all';

// Conditionally render the mock sign-in page for testing
const useMockSignInPage = true; // Set this to false to render the actual app

root.render(
  <Provider store={store}>
    <AuthProvider>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/program" element={<Program />} />
          <Route path="/train" element={<Train />} />
          <Route path="/progress" element={<Progress />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={useMockSignInPage ? <MockSignInPage /> : <Login />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/test" element={<Test />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals.console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
